/* Reset start */

.display {
  display: block;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  cursor: default;
}

body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
  white-space: nowrap;
  overflow: auto;
}

a {
  background: transparent;
  text-decoration: none;
  color: inherit;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

:active,
:focus,
:hover {
  outline: none;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

ol,
ul {
  list-style: none;
}

pre {
  white-space: pre-wrap;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

q {
  quotes: "\201C""\201D""\2018""\2019";
}

img {
  border: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button {
  overflow: visible;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 0;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}
button[disabled],
html input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

button,
input,
select[multiple],
textarea {
  background-image: none;
}

input,
select,
textarea {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input,
textarea {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  resize: none;
}

[placeholder]::-webkit-input-placeholder {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[placeholder]:-moz-placeholder,
[placeholder]::-moz-placeholder {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[placeholder]:-ms-input-placeholder {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}

[placeholder]:focus:-moz-placeholder,
[placeholder]:focus::-moz-placeholder {
  color: transparent;
}

[placeholder]:focus:-ms-input-placeholder {
  color: transparent;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* Reset end */
